<template>
  <alter-form
    labelWidth="150px"
    :watchImageVisible="true"
    :collapseLength="collapseLength"
    :modifyData="modifyData"
    @formSubmit="formSubmit"
    :formData="formData"
  />
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
//api
import {
  createRestContent, 
  getRestCotentInfoPreview,
  updateRestCotentInfo
} from '@/services/content';
import { getRestCityList } from '@/services/setting'
import constant from '@/utils/constant.js'
import jsonData from './jsonData'
import utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import _ from 'lodash'
export default {
  components: {
    alterForm
  },
  data () {
    return {
      constant,
      formData: _.cloneDeep(jsonData.formData),
      modifyData: {},
      collapseLength: [],
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    async initData () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const params = {page: 1, per_page: 1000}
      const res = await getRestCityList(params)
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[5].options = arr
      if (this.id) {
        const restRes = await getRestCotentInfoPreview(this.id)
        if (restRes.status == 200 && restRes.data) {
          const placeObj = JSON.parse(JSON.stringify(restRes.data.rest_place))
          placeObj.images_attributes = placeObj.images
          delete placeObj['images']
          this.modifyData = { ...placeObj }
        }
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.rank = Number(obj.rank)
      if (obj.images_attributes && obj.images_attributes.length > 0) {
        obj.images_attributes.forEach((d) => {
          if (Object.hasOwn(d, '_destroy')) {
            delete d['_destroy']
          }
        })
      }
      let api = () => createRestContent(obj)
      if (this.id) {
        api = () => updateRestCotentInfo(this.id, obj)
      }
      api().then((res) => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'contentList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
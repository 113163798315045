import request from '@/utils/request'
export const getRestCotentList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_places',
    params
  })
}
export const deleteRestCotent = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_places/${id}`
  })
}

export const createRestContent = (rest_place) => {
  return request({
    method: 'POST',
    url: '/cms/rest_places',
    data: {
      rest_place
    }
  })
}

export const getRestCotentInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_places/${id}/preview`
  })
}

export const updateRestCotentInfo = (id, rest_place) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_places/${id}`,
    data: {
      rest_place
    }
  })
}

export const getRestCotentInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_places/${id}`
  })
}
import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
export default {
  formData: [{
    title: '',
    name: 0,
    createDateItem: [{
      span: 18,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.product.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.ranking'),
      placeholder: i18n.t('placeholder.showRanking'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) },
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.ranking') }), trigger: 'blur' }
        ]
      }
    }, {
      span: 8,
      prop: 'kind',
      name: 'select',
      options: [
        {
          name: i18n.t('form.rest.foodRecommendation'),
          id: 'food'
        },
        {
          name: i18n.t('form.rest.travelGuide'),
          id: 'fun'
        }
      ],
      label: i18n.t('form.rest.showKinds'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.showKinds') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'rest_city_id',
      name: 'select',
      options: [],
      label: i18n.t('form.rest.city'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.city') }) }
        ]
      }
    }, {
      span: 18,
      prop: 'desc',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 18,
      prop: 'desc_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 10
        // imageName: 'remote_image_url'
      }
    }, {
      span: 8,
      prop: 'url',
      name: 'input',
      label: 'URL',
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }]
  }]
}
